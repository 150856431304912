import { ITimeSlotForm } from '@/data/infrastructures/misc/interfaces/pickupTimeManagement'
import {
  CreateDynamicPickupRequestInterface,
  UpdateDynamicPickupRequestInterface,
} from '../contracts/PickupTimeManagementRequest'
import { Utils } from '@/app/infrastructures/misc'

const mapTimeSlot = (
  timeSlot: ITimeSlotForm
): {
  is_disable: boolean,
  status: string
  slot: {
    start_time: string
    end_time: string
    pickup_time_limit: number | undefined
  }[]
} => {
  return {
    is_disable: <boolean>timeSlot.isDisabled,
    status: timeSlot.isActive ? 'active' : 'inactive',
    slot: timeSlot.slots
      .filter(
        slot =>
          slot.id &&
          slot.endTime &&
          slot.startTime &&
          slot.pickupTimeLimit !== null
      )
      .map(slot => ({
        start_time: Utils.formatDateWithIDLocale(
          <string>slot.startTime?.toISOString(),
          'HH:mm'
        ),
        end_time: Utils.formatDateWithIDLocale(
          <string>slot.endTime?.toISOString(),
          'HH:mm'
        ),
        pickup_time_limit: slot.pickupTimeLimit,
      })),
  }
}

export class CreateDynamicPickupRequest
  implements CreateDynamicPickupRequestInterface {
  routes: number[]
  periodeStart: Date
  periodeEnd?: Date
  timeSlot: ITimeSlotForm[]

  constructor(payload: {
    routes: number[]
    periodeStart: Date
    periodeEnd?: Date
    timeSlot: ITimeSlotForm[]
  }) {
    this.routes = payload.routes
    this.periodeStart = payload.periodeStart
    this.periodeEnd = payload.periodeEnd
    this.timeSlot = payload.timeSlot
  }

  toPayload(): string {
    const payload = {
      timezone: '+07:00',
      route_id: this.routes,
      periode_start: Utils.formatDateWithIDLocale(
        this.periodeStart.toISOString(),
        'YYYY-MM-DD'
      ),
      periode_end: this.periodeEnd
        ? Utils.formatDateWithIDLocale(
            this.periodeEnd.toISOString(),
            'YYYY-MM-DD'
          )
        : '',
      time_slot: {
        monday: mapTimeSlot(this.timeSlot[0]),
        tuesday: mapTimeSlot(this.timeSlot[1]),
        wednesday: mapTimeSlot(this.timeSlot[2]),
        thursday: mapTimeSlot(this.timeSlot[3]),
        friday: mapTimeSlot(this.timeSlot[4]),
        saturday: mapTimeSlot(this.timeSlot[5]),
        sunday: mapTimeSlot(this.timeSlot[6]),
      },
    }
    return JSON.stringify(payload)
  }
}

export class UpdateDynamicPickupRequest
  implements UpdateDynamicPickupRequestInterface {
  dynamicPickupId: number
  isDefault?: boolean
  routeId: number
  periodeStart: Date
  periodeEnd?: Date
  timeSlot: ITimeSlotForm[]

  constructor(payload: {
    dynamicPickupId: number
    isDefault?: boolean
    routeId: number
    periodeStart: Date
    periodeEnd?: Date
    timeSlot: ITimeSlotForm[]
  }) {
    this.dynamicPickupId = payload.dynamicPickupId
    this.isDefault = payload.isDefault
    this.routeId = payload.routeId
    this.periodeStart = payload.periodeStart
    this.periodeEnd = payload.periodeEnd
    this.timeSlot = payload.timeSlot
  }

  toPayload(): string {
    const payload = {
      timezone: '+07:00',
      route_id: this.routeId,
      periode_start: this.isDefault
        ? ''
        : Utils.formatDateWithIDLocale(
            this.periodeStart.toISOString(),
            'YYYY-MM-DD'
          ),
      periode_end: this.isDefault
        ? ''
        : this.periodeEnd
        ? Utils.formatDateWithIDLocale(
            this.periodeEnd.toISOString(),
            'YYYY-MM-DD'
          )
        : '',
      time_slot: {
        monday: mapTimeSlot(this.timeSlot[0]),
        tuesday: mapTimeSlot(this.timeSlot[1]),
        wednesday: mapTimeSlot(this.timeSlot[2]),
        thursday: mapTimeSlot(this.timeSlot[3]),
        friday: mapTimeSlot(this.timeSlot[4]),
        saturday: mapTimeSlot(this.timeSlot[5]),
        sunday: mapTimeSlot(this.timeSlot[6]),
      },
    }
    return JSON.stringify(payload)
  }
}
