import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from 'vuex-module-decorators'
import store from '../store'
import { Pagination } from '@/domain/entities/Pagination'
import {
  PickupTime,
  PickupTimeDetail,
  PickupTimes,
} from '@/domain/entities/PickupTimeManagement'
import { PickupTimeManagementPresenter } from '../presenters/PickupTimeManagementPresenter'
import { container } from 'tsyringe'
import { Utils } from '@/app/infrastructures/misc'
import Vue from 'vue'
import {
  CreateDynamicPickupRequest,
  UpdateDynamicPickupRequest,
} from '@/data/payload/api/PickupTimeManagementRequest'

export interface PickupTimeManagementStateInterface {
  paginationData: Pagination
  pickupTimeData: PickupTime[]
  pickupTimeDetailData: PickupTimeDetail
  isLoading: boolean
  isCreateDynamicPickupSuccess: boolean
  isUpdateDynamicPickupSuccess: boolean
  isDeleteDynamicPickupSuccess: boolean
}

@Module({
  namespaced: true,
  store,
  name: 'adjustment-parcel-poin',
  dynamic: true,
})
class PickupTimeManagementController extends VuexModule
  implements PickupTimeManagementStateInterface {
  public presenter: PickupTimeManagementPresenter = container.resolve(
    PickupTimeManagementPresenter
  )
  public paginationData: Pagination = new Pagination()
  public pickupTimeData: PickupTime[] = []
  public pickupTimeDetailData: PickupTimeDetail = {}
  public isLoading = false
  public isCreateDynamicPickupSuccess = false
  public isUpdateDynamicPickupSuccess = false
  public isDeleteDynamicPickupSuccess = false

  @Action({ rawError: true })
  public async getPickupTimes(
    params: Record<string, string | number>
  ): Promise<void> {
    this.setIsLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getPickupTimes(formattedParams)
      .then((res: PickupTimes) => {
        this.setPickupTimes(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Dynamic Pickup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async getDetailPickupTime(id: number): Promise<void> {
    this.setIsLoading(true)

    this.presenter
      .getDetailPickupTime(id)
      .then((res: PickupTimeDetail) => {
        this.setPickupTimeDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Dynamic Pickup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async createDynamicPickupTime(
    payload: CreateDynamicPickupRequest
  ): Promise<void> {
    this.setIsLoading(true)

    this.presenter
      .createDynamicPickupTime(payload)
      .then(() => {
        this.setIsCreateDynamicPickupSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Dynamic Pickup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async updateDynamicPickupTime(
    payload: UpdateDynamicPickupRequest
  ): Promise<void> {
    this.setIsLoading(true)

    this.presenter
      .updateDynamicPickupTime(payload)
      .then(() => {
        this.setIsUpdateDynamicPickupSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Dynamic Pickup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  public async deleteDynamicPickupTime(id: number): Promise<void> {
    this.setIsLoading(true)

    this.presenter
      .deleteDynamicPickupTime(id)
      .then(() => {
        this.setIsDeleteDynamicPickupSuccess(true)
        Vue.notify({
          title: 'Delete Dynamic Pickup Time',
          text: 'Delete Dynamic Pickup Time Successfully',
          type: 'success',
          duration: 5000,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Dynamic Pickup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Mutation
  private setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Mutation
  private setPickupTimes(data: PickupTimes): void {
    this.paginationData = <Pagination>data.pagination
    this.pickupTimeData = <PickupTime[]>data.data
  }

  @Mutation
  private setPickupTimeDetail(data: PickupTimeDetail): void {
    this.pickupTimeDetailData = data
  }

  @Mutation
  public setIsCreateDynamicPickupSuccess(bool: boolean): void {
    this.isCreateDynamicPickupSuccess = bool
  }

  @Mutation
  public setIsUpdateDynamicPickupSuccess(bool: boolean): void {
    this.isUpdateDynamicPickupSuccess = bool
  }

  @Mutation
  public setIsDeleteDynamicPickupSuccess(bool: boolean): void {
    this.isDeleteDynamicPickupSuccess = bool
  }
}

export default getModule(PickupTimeManagementController)
